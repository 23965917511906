/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO(props: SEOProps) {
  const data = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
          }
        }
      }
    `
  );

  const metaDescription =
    props.description || data.wp.generalSettings.description;

  return (
    <Helmet
      key={"helmet-id"}
      title={props.title}
      titleTemplate={`%s | ${data.wp.generalSettings.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(props.meta)}
    >
      <html lang={props.lang} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: MetaWithProperty[] | MetaWithName[];
  title: string;
}

interface MetaWithProperty {
  property: string;
  content: string;
}

interface MetaWithName {
  name: string;
  content: string;
}

export default SEO;
